import { TextField as TextFieldPrimitive } from '@radix-ui/themes'

import * as React from 'react'

import { cn, toKebabCase } from '#app/utils/misc.tsx'
import { ElemLabel } from './ElemLabel'
import { TextElem } from './Typography'

const Input = React.forwardRef<
	React.ElementRef<typeof TextFieldPrimitive.Root>,
	React.ComponentProps<typeof TextFieldPrimitive.Root> & {
		labelText?: string
		showLabel?: boolean
		outerClassName?: string
		error?: string
		isErrorless?: boolean
	}
>(
	(
		{
			children,
			labelText,
			outerClassName,
			showLabel: showLabelProp,
			isErrorless,
			...props
		},
		ref,
	) => {
		const [isActive, setIsActive] = React.useState(false)

		const testid = toKebabCase(labelText?.toString() || 'generic')

		const showLabel = showLabelProp ?? true

		// If there's an error, set isErrorless to false
		// unless isErrorless is explicitly set to something else
		if (props.error !== undefined) {
			if (isErrorless === undefined) {
				isErrorless = false
			}
		}

		return (
			!props.hidden && (
				<div
					data-testid={`${testid}-text-field-outer`}
					className={cn('relative my-4 flex w-full', outerClassName)}
				>
					{showLabel && (
						<ElemLabel
							labelText={labelText}
							isActive={isActive}
							required={props.required}
						/>
					)}
					<div
						data-testid={`${testid}-text-field-root-outer`}
						className={cn(
							'flex grow flex-col',
							!isErrorless && props.error !== undefined ? 'mb-0' : 'mb-4',
						)}
					>
						<TextFieldPrimitive.Root
							data-testid={`${testid}-text-field-root-inner`}
							ref={ref}
							onBlur={() => {
								setIsActive(false)
							}}
							onFocus={() => {
								setIsActive(true)
							}}
							{...props}
						>
							{children}
						</TextFieldPrimitive.Root>
						{!isErrorless && props.error && (
							<TextElem
								size={'1'}
								data-testid={`${testid}-text-field-error`}
								className="text-xs text-red-500"
							>
								{props.error}
							</TextElem>
						)}
					</div>
				</div>
			)
		)
	},
)
Input.displayName = 'Input'

const InputSlot = React.forwardRef<
	React.ElementRef<typeof TextFieldPrimitive.Slot>,
	React.ComponentPropsWithoutRef<typeof TextFieldPrimitive.Slot>
>(({ className, children, ...props }, ref) => {
	return (
		<TextFieldPrimitive.Slot
			ref={ref}
			className={cn('m-1 p-1', className)}
			{...props}
		>
			{children}
		</TextFieldPrimitive.Slot>
	)
})
InputSlot.displayName = 'InputSlot'

export { Input, InputSlot }
