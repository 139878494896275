import { cn, toKebabCase } from '../../../utils/misc'
import { TextElem } from './Typography'

export type ElemLabelProps = {
	labelText?: string
	isActive?: boolean
	required?: boolean
}

export function ElemLabel({ labelText, isActive, required }: ElemLabelProps) {
	const testid = toKebabCase(labelText?.toString() || 'generic')

	let labelTextClean = labelText
	if (labelText) {
		if (labelText.endsWith(':')) {
			if (required && !labelText.endsWith('*:')) {
				labelTextClean = labelText.split(':')[0] + '*:'
			}
		} else {
			if (required && !labelText.endsWith('*')) {
				labelTextClean = labelText + '*:'
			} else {
				labelTextClean = labelText + ':'
			}
		}
	}

	return (
		<>
			{labelText && (
				<TextElem
					data-testid={`${testid}-elem-label`}
					size={'1'}
					truncate={true}
					className={cn(
						'absolute -top-3 left-2 inline-block bg-[var(--color-background)] px-1',
						// 'min-w-24 truncate',
						'rounded-sm border border-[var(--gray-a7)] shadow-sm',
						isActive !== undefined && isActive
							? 'border-[var(--focus-8)]'
							: 'border-[var(--gray-a7)]',
					)}
				>
					{labelTextClean}
				</TextElem>
			)}
		</>
	)
}
